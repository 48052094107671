import React from 'react';
import styled from 'react-emotion';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { Container, Row, Column } from '@common/Grid';
import { Text } from '@common/components/Text';
import Widget from './Widget';
import WidgetDefault from './WidgetDefault';

const Section = styled('div')`
  background-color: ${props => props.backgroundColor};
`;

const ColumnWrapIframe = styled(Column)`
  z-index: 0;
`;

const TextWidget = styled(Text)`
  border: 2px solid #fff;
`;

const WidgetBlock = ({ block, ...props }) => {
  const widget = block?.widget ?? '';
  const backgroundColor = block?.backgroundColor ?? '#ffffff';
  const mode = props.mode ?? '';

  return (
    <Section backgroundColor={backgroundColor}>
      <Container py={[6, 8, 8, 10]} px={[1, 4, 2]}>
        <Row justifyContent="center">
          {widget.length > 0 ? (
            <>
              {mode !== 'public' ? (
                <Column width={1} px={[3, 1, 2]}>
                  <TextWidget
                    py={['119px', '62px']}
                    px={['22px', 0]}
                    bg="#7A8498"
                    fontFamily="Roboto,sans-serif"
                    fontSize={['16px', null, '20px', '24px']}
                    lineHeight="32px"
                    fontWeight="400"
                    color="#ECEBF3"
                    textAlign="center"
                  >
                    <FormattedMessage
                      id="block.installed"
                      defaultMessage="Виджет Рекламный блок Google AdSense установлен"
                    />
                  </TextWidget>
                </Column>
              ) : (
                <ColumnWrapIframe
                  width={[1, null, 5 / 6, 2 / 3]}
                  px={[0, 1, 2]}
                >
                  <Widget {...props} widget={widget} />
                </ColumnWrapIframe>
              )}
            </>
          ) : (
            <Column width={1} px={[3, 1, 2]}>
              <WidgetDefault
                href="https://nethouse.ru/about/instructions/kak_dobavit_blok_google_adsense"
                title="Google AdSense"
              />
            </Column>
          )}
        </Row>
      </Container>
    </Section>
  );
};

WidgetBlock.propTypes = {
  block: PropTypes.shape({
    widget: PropTypes.string,
    backgroundColor: PropTypes.string
  }),
  mode: PropTypes.string
};

export default WidgetBlock;
