import React from 'react';
import styled from 'react-emotion';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { Row, Column } from '@common/Grid';
import { Text } from '@common/components/Text';
import StyledIcon from '@common/components/StyledIcon';
import { Adsense } from './assets/icon';

const Section = styled('a')`
  display: inline-block;
  width: 100%;
  background-color: #fff;
  border: 2px solid #d2d7df;
  text-decoration: none;
  box-sizing: border-box;
  outline: none;
  transition: opacity 0.2s ease-out;

  &:hover,
  &:active {
    opacity: 0.8;
  }
`;

const Mark = styled('mark')`
  background: transparent;
  color: #000;
`;

const WrapImage = styled('div')`
  @media screen and (max-width: 767px) {
    border-bottom: 2px solid #d2d7df;
  }
  @media screen and (min-width: 768px) {
    border-right: 2px solid #d2d7df;
  }
`;

const DefaultWidget = ({ href, title }) => {
  return (
    <Section href={href} target="_blank" title="Как получить код виджета">
      <Row flexWrap="wrap" alignItems="center">
        <Column width={[1, 1 / 3]}>
          <WrapImage>
            <Row justifyContent="center">
              <Column width={2 / 3} py="61px" textAlign="center">
                <StyledIcon width="214" height="34" maxWidth="100%">
                  <Adsense />
                </StyledIcon>
              </Column>
            </Row>
          </WrapImage>
        </Column>
        <Column width={[null, 1 / 12]} />
        <Column width={[1, 1 / 2]} py={6}>
          <Row justifyContent="center">
            <Column width={[5 / 6, 1]}>
              <Text
                fontFamily="Roboto,sans-serif"
                fontSize={['16px', null, '20px', '24px']}
                lineHeight={['24px', null, null, '32px']}
                fontWeight="400"
                color="#59667d"
                textAlign={['center', 'left']}
              >
                <FormattedMessage
                  id="block.default.text"
                  defaultMessage="Здесь будет виджет {name}"
                  values={{
                    name: <Mark>{title}</Mark>
                  }}
                />
              </Text>
            </Column>
          </Row>
        </Column>
      </Row>
    </Section>
  );
};

DefaultWidget.propTypes = {
  href: PropTypes.string,
  title: PropTypes.string
};

export default DefaultWidget;
