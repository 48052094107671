import React, { Component } from 'react';

class Widget extends Component {
  constructor(props) {
    super(props);
    this.iframe = React.createRef();
  }

  componentDidMount() {
    this.replaceWidget(this.props.widget);
  }

  replaceWidget = widget => {
    const fragment = document.createRange().createContextualFragment(widget);

    while (this.iframe.current.firstChild) {
      this.iframe.current.removeChild(this.iframe.current.firstChild);
    }

    const ins = fragment.querySelector('.adsbygoogle');
    if (ins) {
      this.iframe.current.appendChild(ins);
    }

    const lib = document.createElement('script');
    lib.src = '//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js';
    lib.type = 'text/javascript';
    lib.onload = () => {
      while (fragment.childElementCount) {
        this.iframe.current.appendChild(fragment.firstElementChild);
      }
    };
    this.iframe.current.appendChild(lib);
  };

  componentDidUpdate(prevProps) {
    if (prevProps.widget === this.props.widget) {
      return;
    }

    this.replaceWidget(this.props.widget);
  }

  render() {
    if (process.env.SERVER) {
      return null;
    }

    return <div ref={this.iframe} />;
  }
}

export default Widget;
